import { Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { DashboardStore } from '../dashboardStore';
import commonStyles from '../index.less';
import { WaybillItem } from '../waybillItem/waybillItem';

@inject('dashboardStore')
@observer
export class WaybillSurplus extends React.Component<{ dashboardStore?: DashboardStore; }> {
  render() {
    const { data } = this.props.dashboardStore.waybillSurplusStore;
    return (
      <Card bordered={false}>
        <header className={commonStyles.headerContainer}>
          <h2>
            电子面单剩余单数
          </h2>
        </header>
        <div
          style={{
            maxHeight: 150,
            overflowY: 'auto',
          }}
        >
          {
            data.map((item) => {
              return (
                <WaybillItem
                  data={item}
                  key={nanoid()}
                />
              );
            })
          }
        </div>
      </Card>
    );
  }
}
