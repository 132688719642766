import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type { StaticsSummary } from '../types';

export class StaticsSummaryStore {
  @observable public data: StaticsSummary = {
    canDeliver: 0,
    courierNoEmpty: 0,
    orderWave: 0,
    outOfStock: 0,
    refundTaskWave: 0,
    stockTake: 0,
    stopped: 0,
    waitChangeMark: 0,
    waitDeliver: 0,
    errorOrder: 0,
    deliveryWarn: 0,
    returnedButNotArrival: 0,
    sampleOrderOutOfStock: 0,
  };

  @action public getData = () => {
    request<BaseData<StaticsSummary>>({ url: '/api/cloud/wms/report/index/statistics/summary' })
      .then((info) => this.data = info.data || {});
  };
}
