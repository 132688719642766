import { getStaticResourceUrl, formatNumber, egeniePcTheme } from 'egenie-common';
import { Provider, observer } from 'mobx-react';
import React from 'react';
import { Space } from 'antd';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import NoticeBadge from '../components/noticeBadge';
import { SystemType } from '../components/noticeBadge/constant';
import { Dashboard } from './dashboard';
import { DashboardStore } from './dashboard/dashboardStore';
import qs from 'qs';

const layoutStore = new LayoutStore();
layoutStore.handleInit();

const dashboardStore = new DashboardStore();
dashboardStore.handleInit();

export default observer(() => {
  const returnedButNotArrival = formatNumber(dashboardStore.staticsSummaryStore.data.returnedButNotArrival);
  return (
    <LayoutGuide
      defaultDashboard={(
        <Provider dashboardStore={dashboardStore}>
          <Dashboard/>
        </Provider>
      )}
      haveDashboard
      logoImg={(
        <img
          onDragStart={(e) => e.preventDefault()}
          src={getStaticResourceUrl('pc/ts/egenie-common/images/wmsLogo.png')}
          style={{ width: '100%' }}
        />
      )}
      store={layoutStore}
      userInfoLeft={(
        <Space size="small">
          {
            returnedButNotArrival > 0 ? (
              <span
                onClick={() => {
                  window.top?.egenie?.openTabId(2690, qs.stringify({
                    platformStatus: '3',
                    sourceType: '6',
                    returnedButNotArrival: 1,
                  }));
                }}
                style={{
                  color: egeniePcTheme.color['egenie-error-color'],
                  cursor: 'pointer',
                }}
              >
                退回未到仓
                {returnedButNotArrival}
                件
              </span>
            ) : null
          }
          <NoticeBadge
            systemType={SystemType.CLOUD_WMS_PC}
          />
        </Space>
      )}
      userInfoRight={[
        {
          id: 'export',
          name: '导入导出任务中心',
          callback: () => window?.top?.egenie?.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
        },
      ]}
    />
  );
});
