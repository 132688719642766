import { Card } from 'antd';
import { getStaticResourceUrl } from 'egenie-common';
import React from 'react';
import commonStyles from '../index.less';
import toolsStyles from './tools.less';

const reference = [
  {
    name: 'PDA下载',
    url: 'https://cdss-wms.obs.cn-east-3.myhuaweicloud.com/cloud-wms/pda/cloud.apk',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/pda.png'),
    download: 'PDA',
  },
  {
    name: '菜鸟组件下载',
    url: 'https://cdn-cloudprint.cainiao.com/waybill-print/cainiao-x-print/prod/win/cainiao-x-print-win-86.exe',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/caiNiao.png'),
    download: '菜鸟组件',
  },
  {
    name: '抖店打印组件下载',
    url: 'https://logistics.douyinec.com/davinci/CloudPrintClient',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/DYPrint.png'),
    download: '抖店打印组件',
  },
  {
    name: '拼多多打印组件下载',
    url: 'https://meta.pinduoduo.com/api/one/app/v1/lateststable?appId=com.xunmeng.pddprint&platform=windows',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/pinDuoDuoPrint.png'),
    download: '拼多多打印组件',
  },
  {
    name: '京东打印组件下载',
    url: 'https://prod-oms-app-cprt.jdwl.com/OpenCloudPrint/setup.exe',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/JDPrint.png'),
    download: '京东打印组件',
  },
  {
    name: '快手打印组件',
    url: 'https://s1-11586.kwimgs.com/kos/nlav11586/kuaishou-print-installer.exe',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/ksPrint.png'),
    download: '快手打印组件',
  },
  {
    name: '视频号打印组件',
    url: 'https://res.wx.qq.com/shop/print/ChannelsShopPrintClient-setup.exe',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/vipshop/sphxd.png'),
    download: '视频号打印组件',
  },
  {
    name: '小红书打印组件',
    url: 'https://xhswaybill-printer-1251524319.cos.ap-shanghai.myqcloud.com/XHPrintTool/prod/win/xiaohongshu-win.exe',
    img: getStaticResourceUrl('pc/ts/egenie-common/images/xhsPrint.png'),
    download: '小红书打印组件',
  },
];

export function Tools() {
  return (
    <Card bordered={false}>
      <header className={commonStyles.headerContainer}>
        <h2>
          工具小助手
        </h2>
      </header>
      <div className={toolsStyles.toolsContainer}>
        {
          reference.map((item) => (
            <a
              download={item.download}
              href={item.url}
              key={item.name}
              rel="noreferrer"
              target={item.download ? undefined : '_blank'}
            >
              <img
                className={commonStyles.img}
                src={item.img}
              />
              <span>
                {item.name}
              </span>
            </a>
          ))
        }
      </div>
    </Card>
  );
}
