import NotificationDrawerStore from '../../notificationDrawer/store';
import NotificationDrawer from '../../notificationDrawer';
import styles from './index.less';
import React, { useEffect, useMemo, useState } from 'react';
import Model from './model';
import { observer } from 'mobx-react';
import { Button, notification } from 'antd';
import NoticeLogoBlack from '../../../assets/images/noticeLogoBlack.png';
import NoticeClose from '../../../assets/images/noticeClose.png';
import SSWebsocket from '../../../utils/websocket';
import type { StructuredContentMap } from '../../notificationDrawer/content/interface';
import qs from 'qs';
import type { User } from '../../../utils/layout/interface';
import { request } from 'egenie-common';

const notificationCenterStore = new NotificationDrawerStore({ parent: this });

const NoticeBadge = ({
  systemType,
  userDimension = false,
  callback,
}: { systemType: number; userDimension?: boolean; callback?: (unread: number) => void; }) => {
  const [
    currentNotReadCount,
    setCurrentNotReadCount,
  ] = useState(0);
  const [
    user,
    setUser,
  ] = useState(null);
  const store = useMemo(() => {
    return new Model(systemType || 0, callback);
  }, [
    systemType,
    callback,
  ]);
  useEffect(() => {
    if (store) {
      store.unMessageNum = currentNotReadCount;
    }
  }, [
    currentNotReadCount,
    store,
  ]);
  useEffect(() => {
    const loadUser = async() => {
      const res: User = await request({ url: '/api/dashboard/user' });
      setUser(res);
    };
    loadUser();
  }, []);

  useEffect(() => {
    if (systemType && user) {
      try {
        const url = window.location.hostname;
        SSWebsocket.connectURL = `wss://${url}/api/websocket/boss/baseinfo/notification/rest/message/push/${user.id}/${systemType}`;
        SSWebsocket.init((message) => {
          const receivedContent = JSON.parse(message);
          if (receivedContent.type === 1) {
            messageChange(receivedContent.data);
          } else if (receivedContent.type === 2) {
            setCurrentNotReadCount(() => receivedContent.data.notReadCount);
          }
        });
      } catch (e) {
        console.log('websocket init error', e);
      }
    }
    return () => {
      SSWebsocket.websocket && SSWebsocket.close();
    };
  }, [
    user,
    systemType,
  ]);

  const messageChange = (receive) => {
    const key = `open${Date.now()}`;
    const closeMessage = () => {
      store.closeMessage(receive.messageId);
      notification.close(key);
    };
    const gotoDetail = (): void => {
      const {
        messageId,
        jumpUrl,
        jumpParamContentMap,
        title,
        hasRead,
        needJumpNewPage,
        needJump,
      } = receive;
      if (!hasRead) {
        store.hasReadMessage(messageId);
      }

      // 不需要跳转直接返回
      if (needJump === false) {
        return;
      }

      const params = qs.stringify(jumpParamContentMap);
      const url = `${jumpUrl}?${params}`;
      const menuResourceId = jumpParamContentMap.menuResourceId;
      const menuName = jumpParamContentMap?.menuTitle || title;
      if (needJumpNewPage) {
        window.open(url);
      } else {
        if (menuResourceId) {
          top.egenie.openTabId(menuResourceId, params);
        } else {
          top.egenie.openTab(url, `${menuResourceId}`, menuName);
        }
      }
    };
    const btn = (
      <Button
        onClick={gotoDetail}
        size="small"
        type="primary"
      >
        查看详情
      </Button>
    );
    const message = (
      <span className={styles.noticeTitle}>
        重要消息
      </span>
    );
    const icon = (
      <img
        className={styles.noticeLogo}
        src={NoticeLogoBlack}
      />
    );
    const closeIcon = (
      <div onClick={closeMessage}>
        <img
          className={styles.closeLogo}
          src={NoticeClose}
        />
      </div>
    );
    const renderMsgContent = (structuredContentMap: StructuredContentMap[], hasRead: boolean) => {
      return (structuredContentMap || []).map(({
        key,
        val,
      }) => {
        return (
          <div
            className={`${styles.messageContent} ${styles.fs12} ${styles.fw400} ${styles.colorLight}  ${styles.mb4} ${styles.content}`}
            key={key}
          >
            {key}
            ：
            {val}
          </div>
        );
      });
    };
    const description = (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <div className={`${styles.fs14} ${styles.title} ${styles.mb4}`}>
          {receive.title}
        </div>
        {receive.structureType === 1 ? receive.strContent : renderMsgContent(receive.structuredContentList, false)}
      </div>
    );
    const close = () => {
      notification.close(key);
    };
    notification.open({
      message,
      icon,
      description,
      btn,
      key,
      closeIcon,
      top: 150,
      maxCount: 1,
      duration: null,
      style: {
        borderRadius: 12,
        width: 271,
        marginLeft: 10,
      },
    });
  };
  const {
    unMessageNum,
    getUnReadMessage,
  } = store;
  return (
    <span>
      <span
        className={styles.msg}
        onClick={() => {
          notificationCenterStore.show();
        }}
        style={{ cursor: 'pointer' }}
      >
        消息
        {unMessageNum > 0 && (
          <div className={styles.numWrap}>
            <div className={`${styles.notificationNumCommon} ${unMessageNum > 99 ? styles.notificationNumMore : styles.notificationNum}`}>
              {unMessageNum > 99 ? '99+' : unMessageNum}
            </div>
          </div>
        )}
        <span className={styles.splitIcon}>
          |
        </span>
      </span>

      <NotificationDrawer
        handleReadMessage={getUnReadMessage}
        store={notificationCenterStore}
        systemType={systemType}
        userDimension={userDimension}
      />
    </span>
  );
};
export default observer(NoticeBadge);
