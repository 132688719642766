import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type { RecentDeliverItem } from '../types';
import { ENUM_RECENT_DELIVER_TYPE } from './recentDeliver';

export class RecentDeliverStore {
  @observable public activeTab: string = ENUM_RECENT_DELIVER_TYPE.today.value;

  @observable public todayDeliverData: RecentDeliverItem[] = [];

  @observable public recentSevenDaysDeliverData: RecentDeliverItem[] = [];

  @action public handleActiveTab = (activeTab: string): void => {
    this.activeTab = activeTab;
    this.getData();
  };

  @action public getData = (): void => {
    if (this.activeTab === ENUM_RECENT_DELIVER_TYPE.today.value) {
      request<BaseData<RecentDeliverItem[]>>({ url: '/api/cloud/wms/report/index/statistics/deliver/today' })
        .then((info) => this.todayDeliverData = info.data || []);
    } else {
      request<BaseData<RecentDeliverItem[]>>({ url: '/api/cloud/wms/report/index/statistics/deliver/recent' })
        .then((info) => this.recentSevenDaysDeliverData = info.data || []);
    }
  };
}
