import { Card, Tabs } from 'antd';
import * as echarts from 'echarts';
import EchartsForReact from 'echarts-for-react';
import { formatNumber } from 'egenie-common';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { DashboardStore } from '../dashboardStore';
import recentDeliverStyles from './recentDeliver.less';

const primaryColor = '#1978ff';

interface SeriesDataItem {
  name: string;
  value: number;
  date?: string;
}

function Bar({ seriesData }: { seriesData: SeriesDataItem[]; }) {
  const options = {
    color: [primaryColor],
    xAxis: {
      data: seriesData.map((item) => item.name),
      type: 'category',
      axisLabel: {
        color: '#2B2E3F',
        fontWeight: 400,
      },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    tooltip: {
      confine: true,
      show: true,
      borderWidth: 0,
      extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);',
      formatter: (params) => {
        return `<h3>${params?.data?.date}</h3><span style="color:#6D6E78">${params.data?.name} <span style="color:${primaryColor}">${params.value}</span> 件</span>`;
      },
    },
    grid: {
      left: 0,
      top: 10,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#999BA4',
        fontWeight: 400,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(0, 0, 0, 0.08)',
        },
      },
    },
    series: [
      {
        data: seriesData,
        type: 'bar',
        barWidth: 24,
        name: '当天发货',
      },
    ],
  };

  return (
    <EchartsForReact
      option={options}
      style={{ height: 210 }}
    />
  );
}

function Line({ seriesData }: { seriesData: SeriesDataItem[]; }) {
  const options = {
    color: [primaryColor],
    xAxis: {
      data: seriesData.map((item) => item.name),
      type: 'category',
      axisLabel: {
        color: '#2B2E3F',
        fontWeight: 400,
      },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        axis: 'x',
        lineStyle: { color: primaryColor },
      },
      confine: true,
      show: true,
      borderWidth: 0,
      extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);',
      formatter: ([params]) => {
        return `<h3>${params?.data?.name}</h3><span style="color:#6D6E78"> ${params.seriesName} <span style="color:${primaryColor}">${params.value}</span> 件</span>`;
      },
    },
    grid: {
      left: 0,
      top: 10,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#999BA4',
        fontWeight: 400,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(0, 0, 0, 0.08)',
        },
      },
    },
    series: [
      {
        data: seriesData,
        type: 'line',
        name: '发货',
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(25, 120, 255, 0.2)',
              },
              {
                offset: 1,
                color: 'rgba(76, 146, 253, 0)',
              },
            ]),
          },
        },
      },
    ],
  };

  return (
    <EchartsForReact
      option={options}
      style={{ height: 210 }}
    />
  );
}

export const ENUM_RECENT_DELIVER_TYPE = {
  today: {
    value: 'today',
    label: '当天发货',
  },
  recentSevenDays: {
    value: 'recentSevenDays',
    label: '近7天发货量',
  },
};

@inject('dashboardStore')
@observer
export class RecentDeliver extends React.Component<{ dashboardStore?: DashboardStore; }> {
  render() {
    const {
      activeTab,
      handleActiveTab,
      todayDeliverData,
      recentSevenDaysDeliverData,
    } = this.props.dashboardStore.recentDeliverStore;

    const items = [
      {
        label: ENUM_RECENT_DELIVER_TYPE.today.label,
        key: ENUM_RECENT_DELIVER_TYPE.today.value,
        children: (
          <Bar
            seriesData={todayDeliverData.map((item) => ({
              name: item.horizontalAxisValue,
              value: formatNumber(item.verticalAxisValue),
              date: item.date,
            }))}
          />
        ),
      },
      {
        label: ENUM_RECENT_DELIVER_TYPE.recentSevenDays.label,
        key: ENUM_RECENT_DELIVER_TYPE.recentSevenDays.value,
        children: (
          <Line
            seriesData={recentSevenDaysDeliverData.map((item) => ({
              name: item.horizontalAxisValue,
              value: formatNumber(item.verticalAxisValue),
            }))}
          />
        ),
      },
    ];

    return (
      <Card bordered={false}>
        <div className={recentDeliverStyles.recentDeliver}>
          <Tabs
            activeKey={activeTab}
            items={items}
            onChange={handleActiveTab}
            size="large"
          />
        </div>
      </Card>
    );
  }
}
