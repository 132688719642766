import { formatNumber, getStaticResourceUrl } from 'egenie-common';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { DashboardStore } from '../dashboardStore';
import { Command } from './command';
import summaryStaticsStyles from './summaryStatics.less';
import moment from 'moment';

export const INNER_EMPTY_STATUS = {
  notEmpty: {
    value: 'SEARCH_FOR_IS_NOT_NULL',
    label: '非空',
  },
  isEmpty: {
    value: 'SEARCH_FOR_IS_NULL',
    label: '为空',
  },
};

@inject('dashboardStore')
@observer
export class PendingInstructions extends React.Component<{ dashboardStore?: DashboardStore; }> {
  render() {
    const {
      dashboardStore: {
        staticsSummaryStore: {
          data: {
            stopped,
            refundTaskWave,
            orderWave,
            errorOrder,
            deliveryWarn,
            unpackageTimeOut,
            sampleOrderOutOfStock,
          },
        },
      },
    } = this.props;
    const data = [
      {
        title: '发货拦截',
        value: formatNumber(stopped),
        params: {
          courierPrintMarkState: '2',
          stopState: '1',
        },
        id: 1640,
      },
      {
        title: '退货拣货',
        value: formatNumber(refundTaskWave),
        params: { wmsPickOrderType: '3' },
        id: 2668,
      },
      {
        title: '发货拣货',
        value: formatNumber(orderWave),
        params: { wmsPickOrderType: '1' },
        id: 2668,
      },
      {
        title: '发货异常',
        value: formatNumber(errorOrder),
        id: 60111,
      },
      {
        title: '有货超时',
        value: formatNumber(deliveryWarn),
        params: {
          date: [
            '10',
            moment().subtract(24, 'hour')
              .format('YYYY-MM-DD HH:mm:ss'),
            moment().subtract(20, 'hour')
              .format('YYYY-MM-DD HH:mm:ss'),
          ].join(','),
          wmsOrderStates: '7,6,5,4,3,2,1',
          stopState: '0',
          cancel: '0',
          outOfStock: '1',
        },
        id: 1640,
      },
      {
        title: '拆包超时',
        value: formatNumber(unpackageTimeOut),
        id: 60031,
        params: {
          unpackageTimeOut: 1,
          date: '1',
          unpackingStatusList: '0',
        },
      },
      {
        title: '样衣缺货',
        value: formatNumber(sampleOrderOutOfStock),
        params: {
          saleOrderOriginTypes: '5',
          purchaseState: '1',
          orderTypes: '2',
        },
        id: 1640,
      },
    ];

    return (
      <Command
        backgroundImageUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/pendingInstructions.png')}
        className={summaryStaticsStyles.pendingInstructions}
        data={data}
        title="待处理指令"
      />
    );
  }
}

@inject('dashboardStore')
@observer
export class TodayStatics extends React.Component<{ dashboardStore?: DashboardStore; }> {
  render() {
    const {
      dashboardStore: {
        staticsSummaryStore: {
          data: {
            waitDeliver,
            canDeliver,
            outOfStock,
            courierNoEmpty,
            waitChangeMark,
          },
        },
      },
    } = this.props;
    const data = [
      {
        title: '待发货',
        value: formatNumber(waitDeliver),
        params: {
          examineGoods: '0',
          stopState: '0',
          cancel: '0',
        },
        id: 1640,
      },
      {
        title: '可发数量',
        value: formatNumber(canDeliver),
        params: {
          examineGoods: '0',
          stopState: '0',
          cancel: '0',
          outOfStock: '1',
        },
        id: 1640,
      },
      {
        title: '缺货数量',
        value: formatNumber(outOfStock),
        params: {
          examineGoods: '0',
          stopState: '0',
          cancel: '0',
          outOfStock: '0',
        },
        id: 1640,
      },
      {
        title: '无单号数量',
        value: formatNumber(courierNoEmpty),
        params: {
          stopState: '0',
          cancel: '0',
          courierNo: INNER_EMPTY_STATUS.isEmpty.value,
        },
        id: 1640,
      },
      {
        title: '待换标数量',
        value: formatNumber(waitChangeMark),
        params: {
          wmsOrderAddedServiceStates: '1',
          courierPrintMarkState: '1',
          stopState: '0',
          cancel: '0',
        },
        id: 1640,
      },
    ];

    return (
      <Command
        backgroundImageUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/todayStatics.png')}
        className={summaryStaticsStyles.todayStatics}
        data={data}
        title="今日数据汇总"
      />
    );
  }
}

