// @ts-ignore
import type socket from '@/types/api/socket';

const SSWebsocket: socket = {
  websocket: null,
  connectURL: '',

  // 开启标识
  socket_open: false,

  // 心跳timer
  hearbeat_timer: null,

  // 心跳发送频率
  hearbeat_interval: 3 * 10000,

  // 是否自动重连
  is_reonnect: true,

  // 重连次数
  reconnect_count: 20,

  // 已发起重连次数
  reconnect_current: 1,

  // 重连timer
  reconnect_timer: null,

  // 重连频率
  reconnect_interval: 10 * 1000,
  receiveMessage: null,
  init: (receiveMessage: (msg: any) => void) => {
    if (!('WebSocket' in window)) {
      console.log('浏览器不支持WebSocket');
      return null;
    }
    SSWebsocket.receiveMessage = receiveMessage;
    SSWebsocket.websocket = new WebSocket(SSWebsocket.connectURL);
    SSWebsocket.websocket.onmessage = (e: MessageEvent) => {
      if (receiveMessage) {
        receiveMessage(e.data);
      }
    };
    SSWebsocket.websocket.onclose = (e: any) => {
      SSWebsocket.socket_open = false;

      // 需要重新连接
      if (SSWebsocket.is_reonnect) {
        SSWebsocket.reconnect_timer = setTimeout(() => {
          // 超过重连次数
          if (SSWebsocket.reconnect_current > SSWebsocket.reconnect_count) {
            clearTimeout(SSWebsocket.reconnect_timer);
            SSWebsocket.is_reonnect = false;
            return;
          }

          // 记录重连次数
          SSWebsocket.reconnect_current++;
          SSWebsocket.reconnect(receiveMessage);
        }, SSWebsocket.reconnect_interval);
      }
    };

    // 连接成功
    SSWebsocket.websocket.onopen = function() {
      SSWebsocket.socket_open = true;
      SSWebsocket.is_reonnect = true;

      // 开启心跳
      SSWebsocket.heartbeat();
    };

    // 连接发生错误
    SSWebsocket.websocket.onerror = function() {
      console.log('socket error');
    };
  },
  heartbeat: () => {
    SSWebsocket.hearbeat_timer && clearInterval(SSWebsocket.hearbeat_timer);

    SSWebsocket.hearbeat_timer = setInterval(() => {
      SSWebsocket.send({ msg: 'ping' });
    }, SSWebsocket.hearbeat_interval);
  },
  send: (data: string, callback = null) => {
    // 开启状态直接发送
    if (SSWebsocket.websocket && SSWebsocket.websocket.readyState === SSWebsocket.websocket.OPEN) {
      SSWebsocket.websocket.send(JSON.stringify(data));

      // @ts-ignore
      callback && callback();
    } else {
      clearInterval(SSWebsocket.hearbeat_timer);
      console.log('socket链接已断开');
    }
  },
  close: () => {
    SSWebsocket.is_reonnect = false;
    SSWebsocket.websocket.close();
    SSWebsocket.websocket = null;
  },

  /**
   * 重新连接
   */
  reconnect: () => {
    if (SSWebsocket.websocket && !SSWebsocket.is_reonnect) {
      SSWebsocket.close();
    }
    SSWebsocket.init(SSWebsocket.receiveMessage);
  },
};
export default SSWebsocket;

