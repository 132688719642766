import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type { WaybillDataItem } from '../types';

export class WaybillSurplusStore {
  @observable public data: WaybillDataItem[] = [];

  @action public getData = () => {
    request<BaseData<WaybillDataItem[]>>({ url: '/api/cloud/wms/rest/index/statics/waybill_balance_query' })
      .then((info) => this.data = info.data || []);
  };
}
