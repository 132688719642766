import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { getStaticResourceUrl, phoneReg } from 'egenie-common';
import React from 'react';
import commonStyles from '../index.less';
import contactAndFeedbackStyles from './contactAndFeedback.less';

export function ContactAndFeedback() {
  return (
    <Card bordered={false}>
      <div className={contactAndFeedbackStyles.contactAndFeedback}>
        <Row gutter={20}>
          <Col span={12}>
            <header className={commonStyles.headerContainer}>
              <h2>
                联系客服
              </h2>
            </header>
            <div className={contactAndFeedbackStyles.leftContainer}>
              <div className={contactAndFeedbackStyles.userAvatar}>
                <img src={getStaticResourceUrl('pc/ts/egenie-common/images/service.png')}/>
                <div className={contactAndFeedbackStyles.nickName}>
                  <span className={contactAndFeedbackStyles.serviceName}>
                    精灵客服(客服)
                  </span>
                  <span className={contactAndFeedbackStyles.onLine}>
                    在线
                  </span>
                </div>
              </div>
              <div className={contactAndFeedbackStyles.contactWay}>
                <span className={contactAndFeedbackStyles.label}>
                  电话:
                </span>
                <span>
                  028-60116498
                </span>
              </div>
              <div className={contactAndFeedbackStyles.contactWay}>
                <span className={contactAndFeedbackStyles.label}>
                  QQ:
                </span>
                <Typography.Text
                  className={contactAndFeedbackStyles.qq}
                  copyable={{
                    text: '2893420754',
                    icon: (
                      <span className={contactAndFeedbackStyles.copy}>
                        复制
                      </span>
                    ),
                    tooltips: false,
                  }}
                >
                  2893420754
                </Typography.Text>

              </div>
            </div>
          </Col>
          <Col span={12}>
            <header className={commonStyles.headerContainer}>
              <h2>
                建议反馈
              </h2>
            </header>
            <Form requiredMark={false}>
              <Form.Item
                name="feedBacktext"
                rules={[
                  {
                    required: true,
                    message: '请输入您的问题',
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={{
                    minRows: 3,
                    maxRows: 3,
                  }}
                  disabled
                  placeholder="请输入您的问题"
                />
              </Form.Item>
              <h2>
                联系电话
              </h2>
              <Row gutter={12}>
                <Col span={16}>
                  <Form.Item
                    name="feedBackphone"
                    rules={[
                      {
                        required: true,
                        message: '请输入手机号',
                      },
                      {
                        pattern: phoneReg,
                        message: '请输入正确手机号',
                      },
                    ]}
                  >
                    <Input
                      disabled
                      placeholder="请输入11位有效手机号"
                      type="phone"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Button
                      block
                      disabled
                      htmlType="submit"
                      type="primary"
                    >
                      提交
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Card>
  );
}
