import { Checkbox, Empty, Pagination, Spin, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { api } from '../../../utils/api';
import styles from './index.less';
import type { MessageContentVO, StructuredContentMap } from './interface';
import qs from 'qs';
import { SystemType } from '../../components/noticeBadge/constant';
import _ from 'lodash';

const NotificationContent = observer(({
  store,
  callback,
}) => {
  const {
    messageTypeList,
    loading,
    isRead,
    messageType,
    data,
    page,
    pageSize,
    total,
    onChangeActiveTab,
    onPaginationChange,
    changeCheckBox,
    setAllRead,
    queryMessageList,
    hasReadMessage,
    systemType,
  } = store;

  console.log(messageType);

  const gotoDetail = ({
    messageId,
    jumpUrl,
    jumpParamContentMap,
    title,
    hasRead,
    needJumpNewPage,
    needJump,
  }: MessageContentVO): void => {
    if (!hasRead) {
      hasReadMessage(messageId);
    }

    // 不需要跳转直接返回
    if (needJump === false) {
      return;
    }

    const params = qs.stringify(jumpParamContentMap);
    const url = `${jumpUrl}?${params}`;
    const menuResourceId = jumpParamContentMap.menuResourceId;
    const menuName = jumpParamContentMap?.menuTitle || title;
    if (needJumpNewPage) {
      window.open(url);
    } else {
      if (menuResourceId) {
        top.egenie.openTabId(menuResourceId, params);
      } else {
        top.egenie.openTab(url, `${menuResourceId}`, menuName);
      }
    }
    callback && callback();
  };

  useEffect(() => {
    queryMessageList();
  }, []);
  const renderMsgContent = (structuredContentMap: StructuredContentMap[], hasRead: boolean) => {
    return (structuredContentMap || []).map(({
      key,
      val,
    }) => {
      return (
        <div
          className={`${styles.messageContent} ${styles.fs12} ${styles.fw400} ${styles.colorLight}  ${styles.mb4} `}
          key={key}
          style={{ color: !hasRead ? '#2b2e3e' : '#999ba4' }}
        >
          {key}
          ：
          {val}
        </div>
      );
    });
  };

  return (
    <div className={styles.noticeCenterWrap}>
      <Spin spinning={loading}>
        <div className={styles.header}>
          <Checkbox
            checked={isRead}
            onChange={changeCheckBox}
          >
            <span
              className={`${styles.fs12} ${styles.color333} ${styles.fw400}`}
            >
              仅查看未读消息
            </span>
          </Checkbox>
          {systemType === SystemType.BOSS_PC && (
            <div
              className={`${styles.allRead} ${styles.fs12} ${styles.color333} ${styles.fw400}`}
              onClick={() => {
                setAllRead();
              }}
            >
              <img
                alt=""
                height={14}
                src={`${api.oss}/images/hasRead.png`}
                width={14}
              />
              <span style={{ marginLeft: 4 }}>
                全部已读
              </span>
            </div>
          )}
         
        </div>
        <div className={styles.notificationCenter}>
          <div className={styles.left}>
            <div
              className={styles.tabs}
            >
              {messageTypeList.map((item) => (
                <div
                  className={messageType === item.label ? `${styles.tabItem} ${styles.tabItemActive}` : styles.tabItem}
                  key={item.label}
                  onClick={() => onChangeActiveTab(item.label)}
                >
                  {item.label}
                  {item.count > 0 ? (
                    <span className={styles.unreadNum}>
                      {item.count > 99 ? '99+' : item.count}
                    </span>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.right}>
            {data.length > 0 ? (
              <div className={styles.messageListWrap}>
                {
                  data?.map((item) => {
                    const {
                      id,
                      hasRead,
                      title,
                      createTime,
                      structureType,
                      structuredContentList,
                      strContent,
                      jumpUrl,
                      jumpParamContentMap,
                      messageId,
                      needJumpNewPage,
                      lastUpdaterName,
                      lastUpdateTime,
                    } = item;
                    return (
                      <div
                        className={styles.messageItem}
                        key={id}
                      >
                        <div
                          className={`${styles.messageHeader} ${styles.fs14} ${styles.fw500} ${hasRead ? styles.colorLight : styles.colorHead} ${styles.mb4}`}
                        >
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          >
                            {!hasRead && <div className={styles.noReadTag}/>}
                            <div
                              onClick={() => gotoDetail(item)}
                              style={{
                                color: !hasRead ? '#2b2e3e' : '#999ba4',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {title}
                              <i className="icon-sq"/>
                            </div>
                          </div>
                          <div
                            className={`${styles.fs12} ${styles.fw400} ${styles.colorLight}`}
                            style={{ color: !hasRead ? '#2b2e3e' : '#999ba4' }}
                          >
                            {createTime}
                          </div>
                        </div>
                        <div
                          className={`${styles.messageInfo} ${styles.fs12} ${styles.fw400} ${styles.colorHead}  ${styles.mb8}`}
                          style={{ color: !hasRead ? '#2b2e3e' : '#999ba4' }}
                        >
                          {structureType === 1 ? strContent : renderMsgContent(structuredContentList, hasRead)}
                        </div>
                        {/** BOSS不显示,未读不显示,已读人为空不显示 */}
                        {systemType !== SystemType.BOSS_PC && hasRead === 1 && !_.isEmpty(lastUpdaterName) && (
                          <Tooltip
                            title={`${lastUpdaterName} ${lastUpdateTime} 已读`}
                          >
                            <div className={styles.hasRead}>
                              已读
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    );
                  })
                }
              </div>
            ) : (!loading && data.length === 0) ? (
              <div className={styles.empty}>
                <Empty
                  description="暂无消息"
                  image={`${api.oss}/images/noMessage.png`}
                />
              </div>
            ) : null}

          </div>
        </div>
        <div className={styles.footer}>
          <Pagination
            current={page}
            onChange={onPaginationChange}
            pageSize={pageSize}
            showQuickJumper
            showSizeChanger
            showTotal={(totalCount: number) => `共${totalCount}条记录`}
            total={total}
          />
        </div>
      </Spin>
    </div>

  );
});

export default NotificationContent;
