import { Button, message, notification } from 'antd';
import type { BaseData } from 'egenie-common';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import { action, observable } from 'mobx';
import React from 'react';
import type { WaybillDataItem } from '../types';
import { WaybillWarnEditModal } from './waybillWarnEditModal';
import { WaybillWarnList } from './waybillWarnList';

export class WaybillWarnStore {
  @observable public data: WaybillDataItem[] = [];

  @action public getData = () => {
    request<BaseData<WaybillDataItem[]>>({ url: '/api/cloud/wms/rest/index/statics/waybill_warn' })
      .then((info) => {
        if (info.data?.length) {
          this.data = info.data;
          notification.open({
            description: <WaybillWarnList waybillWarnData={this.data}/>,
            key: 'getWaybillWarn',
            style: {
              width: 480,
              right: -16,
            },
            top: 48,
            message: `电子面单告警（共${this.data.length}条）`,
            duration: 10,
            btn: (
              <Button
                className="egenie-secondary-content"
                onClick={this.handleEditLimit}
                type="ghost"
              >
                告警设置
              </Button>
            ),
          });
        }
      });
  };

  @action public handleEditLimit = () => {
    renderModal(
      <WaybillWarnEditModal
        callback={((data) => {
          return request({
            url: '/api/cloud/wms/rest/index/statics/waybill_warn_setting',
            method: 'post',
            data,
          })
            .then(() => {
              message.success('设置成功');
              this.getData();
              destroyAllModal();
            });
        })}
        onCancel={destroyAllModal}
      />
    );
  };
}
