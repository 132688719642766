import { action } from 'mobx';
import React from 'react';
import { RecentDeliverStore } from './recentDeliver/recentDeliverStore';
import { StockStaticsStore } from './stockStatics/stockStaticsStore';
import { StaticsSummaryStore } from './summaryStatics/staticsSummaryStore';
import { TodayPurchaseAndDeliverStore } from './todayPurchaseAndDeliver/todayPurchaseAndDeliverStore';
import { WaybillSurplusStore } from './waybillSurplus/waybillSurplusStore';
import { WaybillWarnStore } from './waybillWarn/waybillWarnStore';

export class DashboardStore {
  constructor() {
    setInterval(this.handleRefresh, 1000 * 60 * 5);
  }

  public staticsSummaryStore: StaticsSummaryStore = new StaticsSummaryStore();

  public stockStaticsStore: StockStaticsStore = new StockStaticsStore();

  public recentDeliverStore: RecentDeliverStore = new RecentDeliverStore();

  public waybillWarnStore: WaybillWarnStore = new WaybillWarnStore();

  public todayPurchaseAndDeliverStore: TodayPurchaseAndDeliverStore = new TodayPurchaseAndDeliverStore();

  public waybillSurplusStore: WaybillSurplusStore = new WaybillSurplusStore();

  @action public handleInit = () => {
    this.handleRefresh();
    this.waybillWarnStore.getData();
  };

  @action public handleRefresh = () => {
    this.staticsSummaryStore.getData();
    this.stockStaticsStore.getData();
    this.recentDeliverStore.getData();
    this.todayPurchaseAndDeliverStore.getData();
    this.waybillSurplusStore.getData();
  };
}

