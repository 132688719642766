import { Card, Table, Tabs } from 'antd';
import { toFixed } from 'egenie-common';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { DashboardStore } from '../dashboardStore';
import styles from './todayPurchaseAndDeliver.less';

export const ENUM_PURCHASE_AND_DELIVER_TYPE = {
  purchase: {
    value: 'purchase',
    label: '当日采购情况',
  },
  deliver: {
    value: 'deliver',
    label: '当日发货数量',
  },
};

@inject('dashboardStore')
@observer
export class TodayPurchaseAndDeliver extends React.Component<{ dashboardStore?: DashboardStore; }> {
  render() {
    const {
      activeTab,
      handleActiveTab,
      purchaseData,
      deliverData,
    } = this.props.dashboardStore.todayPurchaseAndDeliverStore;
    const purchaseColumns = [
      {
        title: '序号',
        dataIndex: '_index',
      },
      {
        title: '货主',
        dataIndex: 'ownerName',
      },
      {
        title: '计划采购量',
        dataIndex: 'totalNum',
      },
      {
        title: '实际到货量',
        dataIndex: 'arrivalNum',
      },
      {
        title: '到货率',
        dataIndex: 'arrivalRate',
        render: (text) => `${toFixed(text, 0)}%`,
      },
    ];
    const deliverColumns = [
      {
        title: '序号',
        dataIndex: '_index',
      },
      {
        title: '货主',
        dataIndex: 'ownerName',
      },
      {
        title: '发货量',
        dataIndex: 'orderQuantity',
      },
    ];

    const items = [
      {
        label: ENUM_PURCHASE_AND_DELIVER_TYPE.purchase.label,
        key: ENUM_PURCHASE_AND_DELIVER_TYPE.purchase.value,
        children: (
          <Table
            columns={purchaseColumns}
            dataSource={purchaseData}
            pagination={false}
            rowKey="_index"
            scroll={{
              y: 310,
              x: null,
            }}
            size="small"
          />
        ),
      },
      {
        label: ENUM_PURCHASE_AND_DELIVER_TYPE.deliver.label,
        key: ENUM_PURCHASE_AND_DELIVER_TYPE.deliver.value,
        children: (
          <Table
            columns={deliverColumns}
            dataSource={deliverData}
            pagination={false}
            rowKey="_index"
            scroll={{
              y: 310,
              x: null,
            }}
            size="small"
          />
        ),
      },
    ];

    return (
      <Card bordered={false}>
        <div className={styles.purchaseAndDeliverStatics}>
          <Tabs
            activeKey={activeTab}
            items={items}
            onChange={handleActiveTab}
            size="large"
          />
        </div>
      </Card>
    );
  }
}

