import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import { api } from '../../../utils';

export default class Model {
  @observable public unMessageNum = 0;

  @observable public systemType = 0;

  @observable public callback = null;

  @observable public user = null;

  constructor(systemType: number, callback) {
    this.systemType = systemType;
    if (systemType !== 0) {
      this.getUnReadMessage();
    }
    this.callback = callback;
  }

  @action
  public getUnReadMessage = async() => {
    const res: BaseData<{
      total: number;
    }> = await request({
      url: api.getNotReadMessageStatistics,
      data: { systemType: this.systemType },
      method: 'POST',
    });
    this.unMessageNum = res.data.total;
    this.callback && this.callback(this.unMessageNum);
  };

  @action
  public closeMessage = async(messageId) => {
    await request({
      url: api.closeImportantMsg,
      params: { messageId },
      method: 'PUT',
    });
  };

  @action public hasReadMessage = async(msgId: number): Promise<void> => {
    await request({
      url: api.batchReadMsg,
      params: {
        msgId,
        systemType: this.systemType,
      },
      method: 'PUT',
    });
  };
}
