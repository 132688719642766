import { action, observable } from 'mobx';

export default class NotificationDrawerStore {
  @observable public visible = false;// 弹框显示或隐藏

  @observable public parent = null;

  constructor({ parent }) {
    this.parent = parent;
  }

  @action
  public show = () => {
    this.visible = true;
  };

  @action
  public onClose = () => {
    this.visible = false;
  };
}
