import { Typography } from 'antd';
import { thousandthSeparator } from 'egenie-common';
import qs from 'qs';
import React from 'react';
import summaryStaticsStyles from './summaryStatics.less';

interface CommandProps {
  data: Array<{
    title: string;
    value: number;
    params?: {[key: string]: string | number; };
    id: number | string;
  }>;
  title: string;
  className: string;
  backgroundImageUrl: string;
}

export const Command: React.FC<CommandProps> = function(props) {
  const {
    data,
    title,
    className,
    backgroundImageUrl,
  } = props;

  return (
    <div
      className={className}
      style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
    >
      <header className={summaryStaticsStyles.headerContainer}>
        <h2>
          {title}
        </h2>
      </header>
      <div className={summaryStaticsStyles.content}>
        {
          data.map((item) => {
            return (
              <section
                key={item.title}

                // @ts-ignore
                onClick={() => window?.top?.egenie?.openTabId(item.id, qs.stringify(item.params))}
              >
                <Typography.Text
                  ellipsis
                  title={`${item.value}`}
                >
                  {thousandthSeparator(item.value)}
                </Typography.Text>
                <footer>
                  {item.title}
                  &nbsp;&gt;
                </footer>
              </section>
            );
          })
        }
      </div>
    </div>
  );
};
