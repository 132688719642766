import { Card, Typography } from 'antd';
import EchartsForReact from 'echarts-for-react';
import { toFixed, formatNumber, thousandthSeparator } from 'egenie-common';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { DashboardStore } from '../dashboardStore';
import commonStyles from '../index.less';
import stockStaticsStyles from './stockStatics.less';

interface StockStaticsItem {
  value: number;
  name: string;
  color: string;
  percent: number;
}

@inject('dashboardStore')
@observer
class PieWrapper extends React.Component<{ dashboardStore?: DashboardStore; }> {
  render() {
    const {
      availableNum,
      availableRate,
      lockNum,
      lockRate,
      errorNum,
      errorRate,
      totalNum,
    } = this.props.dashboardStore.stockStaticsStore.data;

    const data = [
      {
        value: formatNumber(availableNum),
        name: '可用库存',
        color: '#1978FF',
        percent: formatNumber(availableRate),
      },
      {
        value: formatNumber(lockNum),
        percent: formatNumber(lockRate),
        name: '锁定库存',
        color: '#02C190',
      },
      {
        value: formatNumber(errorNum),
        percent: formatNumber(errorRate),
        name: '异常库存',
        color: '#FF9948',
      },
    ];

    return (
      <div className={stockStaticsStyles.stockStatics}>
        <div style={{ width: '50%' }}>
          <Pie data={data}/>
        </div>
        <PieDescription
          data={data}
          totalNum={formatNumber(totalNum)}
        />
      </div>
    );
  }
}

const Pie: React.FC<{ data: StockStaticsItem[]; }> = function({ data }) {
  const options = {
    color: data.map((item) => item.color),
    tooltip: { show: false },
    legend: { show: false },
    grid: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    series: [
      {
        silent: true,
        name: '库存量',
        type: 'pie',
        center: [
          '50%',
          '50%',
        ],
        radius: [
          '70%',
          '50%',
        ],
        data,
        label: { show: false },
      },
    ],
  };

  return (
    <EchartsForReact
      option={options}
      style={{ height: 156 }}
    />
  );
};

const PieDescription: React.FC<{ totalNum: number; data: StockStaticsItem[]; }> = function({
  data,
  totalNum,
}) {
  return (
    <div className={stockStaticsStyles.description}>
      <header>
        <span>
          总库存
        </span>
        <span>
          {thousandthSeparator(totalNum)}
          件
        </span>
      </header>
      {
        data.map((item) => (
          <section key={item.name}>
            <section>
              <span style={{ backgroundColor: item.color }}/>
              <span>
                {item.name}
              </span>
            </section>
            <section>
              <Typography.Text
                ellipsis
                title={`${item.value}`}
              >
                {item.value}
                件
              </Typography.Text>
              <Typography.Text
                ellipsis
                style={{ color: item.color }}
              >
                {toFixed(item.percent, 0)}
                %
              </Typography.Text>
            </section>
          </section>
        ))
      }
    </div>
  );
};

export function StockStatics() {
  return (
    <Card bordered={false}>
      <header className={commonStyles.headerContainer}>
        <h2>
          库存量
        </h2>
        <a
          onClick={() => {
            try {
              window.top.egenie.openTabId(1639);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          详情
          <i className="icon-home_gd"/>
        </a>
      </header>
      <PieWrapper/>
    </Card>
  );
}
