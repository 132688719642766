import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type { StockStatics } from '../types';

export class StockStaticsStore {
  @observable public data: StockStatics = {};

  @action public getData = () => {
    request<BaseData<StockStatics>>({ url: '/api/cloud/wms/rest/index/statics/stock' })
      .then((info) => this.data = info.data || {});
  };
}
