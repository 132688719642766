import { Row, Col, Card } from 'antd';
import { getStaticResourceUrl } from 'egenie-common';
import React from 'react';
import { ContactAndFeedback } from './contactAndFeedback/contactAndFeedback';
import styles from './index.less';
import { RecentDeliver } from './recentDeliver/recentDeliver';
import { StockStatics } from './stockStatics/stockStatics';
import { PendingInstructions, TodayStatics } from './summaryStatics/summaryStatics';
import { TodayPurchaseAndDeliver } from './todayPurchaseAndDeliver/todayPurchaseAndDeliver';
import { Tools } from './tools/tools';
import { WaybillSurplus } from './waybillSurplus/waybillSurplus';

export function Dashboard() {
  return (
    <div className={styles.dashboard}>
      <Row
        gutter={[
          8,
          8,
        ]}
      >
        <Col
          span={12}
          style={{ height: 240 }}
        >
          <TodayStatics/>
        </Col>
        <Col
          span={12}
          style={{ height: 240 }}
        >
          <PendingInstructions/>
        </Col>

        <Col
          span={8}
          style={{ height: 300 }}
        >
          <RecentDeliver/>
        </Col>
        <Col
          span={8}
          style={{ height: 300 }}
        >
          <Card bordered={false}>
            <header className={styles.headerContainer}>
              <h2>
                常用菜单
              </h2>
              <a>
                设置
              </a>
            </header>
            <section style={{
              height: 228,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <img
                height={93}
                src={getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/underDevelopment.png')}
                width={100}
              />
              <span style={{ color: '#999ba4' }}>
                功能开发中…
              </span>
            </section>
          </Card>
        </Col>
        <Col
          span={8}
          style={{ height: 300 }}
        >
          <Card bordered={false}>
            <header className={styles.headerContainer}>
              <h2>
                公告
              </h2>
              <a>
                更多
              </a>
            </header>
            <section style={{
              height: 228,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <img
                height={93}
                src={getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/underDevelopment.png')}
                width={100}
              />
              <span style={{ color: '#999ba4' }}>
                功能开发中…
              </span>
            </section>
          </Card>
        </Col>

        <Col
          span={8}
          style={{ height: 452 }}
        >
          <TodayPurchaseAndDeliver/>
        </Col>
        <Col span={16}>
          <Row
            gutter={[
              8,
              8,
            ]}
          >
            <Col
              span={12}
              style={{ height: 220 }}
            >
              <StockStatics/>
            </Col>
            <Col
              span={12}
              style={{ height: 220 }}
            >
              <WaybillSurplus/>
            </Col>
            <Col
              span={12}
              style={{ height: 220 }}
            >
              <ContactAndFeedback/>
            </Col>
            <Col
              span={12}
              style={{ height: 220 }}
            >
              <Tools/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
