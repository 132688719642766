import { nanoid } from 'nanoid';
import React from 'react';
import type { WaybillDataItem } from '../types';
import { WaybillItem } from '../waybillItem/waybillItem';

export const WaybillWarnList: React.FC<{ waybillWarnData: WaybillDataItem[]; }> = function({ waybillWarnData }) {
  return (
    <div
      style={{
        maxHeight: 350,
        overflowY: 'auto',
      }}
    >
      {
        waybillWarnData.map((item) => {
          return (
            <WaybillItem
              data={item}
              key={nanoid()}
            />
          );
        })
      }
    </div>
  );
};

