import { Form, InputNumber, Modal } from 'antd';
import React from 'react';

interface WaybillWarnEditModalProps {
  callback: (params: { thresholdValue: number; }) => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
}

export const WaybillWarnEditModal: React.FC<WaybillWarnEditModalProps> = function({
  callback,
  onCancel,
}) {
  const minThresholdValue = 500;
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: { thresholdValue: number; }; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      mask
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      open
      title="电子面单告警设置"
      width={480}
    >
      <Form
        form={form}
        initialValues={{}}
        labelCol={{ span: 8 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="告警剩余单数≤"
          name="thresholdValue"
          rules={[
            {
              required: true,
              message: '请输入告警值',
            },
          ]}
          style={{ marginTop: 24 }}
        >
          <InputNumber
            autoFocus
            max={99999999}
            min={minThresholdValue}
            placeholder="请输入告警值"
            step={100}
            style={{ width: 190 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
