import { Typography } from 'antd';
import React from 'react';
import type { WaybillDataItem } from '../types';
import styles from './waybillItem.less';

/* eslint-disable @typescript-eslint/no-var-requires */
const enumExpressLogo = {
  default: require('../imgs/defaultExpress.png'),
  YTO: require('../imgs/yto.png'),
  yuantong: require('../imgs/yto.png'),
  POSTB: require('../imgs/ems.png'),
  YZXB: require('../imgs/ems.png'),
  ems: require('../imgs/ems.png'),
  SF: require('../imgs/sf.png'),
  shunfeng: require('../imgs/sf.png'),
};

export const WaybillItem: React.FC<{ data: WaybillDataItem; }> = function({ data }) {
  return (
    <section className={styles.waybillItem}>
      <header>
        <section>
          <img
            alt=""
            height={20}
            src={enumExpressLogo[data.courierNo] || enumExpressLogo.default}
            width={20}
          />
          <Typography.Text
            ellipsis
            title={data.courierName}
          >
            {data.courierName}
          </Typography.Text>
        </section>
        <section>
          <span style={{ flexShrink: 0 }}>
            剩余:&nbsp;&nbsp;
          </span>
          <Typography.Text
            ellipsis
            title={`${data.quantity}`}
          >

            {data.quantity || 0}
          </Typography.Text>
          &nbsp;单
        </section>
      </header>
      <footer>
        <section>
          <Typography.Text
            ellipsis
            title={data.channel}
          >
            面单渠道:&nbsp;&nbsp;
            {data.channel}
          </Typography.Text>
        </section>
        <section>
          <Typography.Text
            ellipsis
            title={`${data.address}`}
          >
            网点:&nbsp;
            {data.address}
          </Typography.Text>
        </section>
      </footer>
    </section>
  );
};
