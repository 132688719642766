import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type { DeliverStaticsItem, PurchaseStaticsItem } from '../types';
import { ENUM_PURCHASE_AND_DELIVER_TYPE } from './todayPurchaseAndDeliver';

export class TodayPurchaseAndDeliverStore {
  @observable public activeTab: string = ENUM_PURCHASE_AND_DELIVER_TYPE.purchase.value;

  @action public handleActiveTab = (activeTab: string): void => {
    this.activeTab = activeTab;
    this.getData();
  };

  @observable public purchaseData: PurchaseStaticsItem[] = [];

  @observable public deliverData: DeliverStaticsItem[] = [];

  @action public getData = () => {
    if (this.activeTab === ENUM_PURCHASE_AND_DELIVER_TYPE.purchase.value) {
      request<BaseData<PurchaseStaticsItem[]>>({ url: '/api/cloud/wms/report/index/statistics/purchase' })
        .then((info) => {
          this.purchaseData = (info.data || []).map((item, index) => ({
            ...item,
            _index: index + 1,
          }));
        });
    } else {
      request<BaseData<DeliverStaticsItem[]>>({ url: '/api/cloud/wms/report/index/statistics/deliver' })
        .then((info) => {
          this.deliverData = (info.data || []).map((item, index) => ({
            ...item,
            _index: index + 1,
          }));
        });
    }
  };
}
